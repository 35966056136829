import rp1 from 'assets/images/wildlife-sighted-hyderabad/DSC4526.png';
import rp2 from 'assets/images/wildlife-sighted-hyderabad/IMG_0013.png';
import rp3 from 'assets/images/wildlife-sighted-hyderabad/20190501_MON_3308.png';
import rp4 from 'assets/images/wildlife-sighted-hyderabad/IMG_5945.png';
import rp5 from 'assets/images/wildlife-sighted-hyderabad/Nursery.png';
import rp6 from 'assets/images/wildlife-sighted-hyderabad/Rain_forest.png';

export const hyderabadWildlifeImg = [
  {
    id: 1,
    img: rp1,
    alt: '',
  },
  {
    id: 2,
    img: rp2,
    alt: '',
  },
  {
    id: 3,
    img: rp3,
    alt: '',
  },
  {
    id: 4,
    img: rp4,
    alt: '',
  },
  {
    id: 5,
    img: rp5,
    alt: '',
  },
  {
    id: 6,
    img: rp6,
    alt: '',
  },
];

export const timelapseImagery = [
  {
    id: 1,
    img: rp4,
    alt: 'Picture taken on plantation day',
  },
  {
    id: 2,
    img: rp5,
    alt: 'Picture taken 3 months after',
  },
  {
    id: 3,
    img: rp6,
    alt: 'Picture taken 6 months after',
  },
  {
    id: 4,
    img: rp1,
    alt: '',
  },
  {
    id: 5,
    img: rp2,
    alt: '',
  },
  {
    id: 6,
    img: rp3,
    alt: '',
  },
];

export const hyderabadListofSpices = [
  {
    "id": 1,
    "scientific_name": "Syzygium travanicorium",
    "common_name": "Poriyal",
    "icun_assessment": "Critically Endangered",
    "remarks": "Less than 25 individual trees on Earth"
  },
  {
    "id": 2,
    "scientific_name": "Canarium strictum",
    "common_name": "Manda -dhupa",
    "icun_assessment": "Vulnerable",
    "remarks": "Source of medicine; incense"
  },
  {
    "id": 3,
    "scientific_name": "Ailanthes triphysa",
    "common_name": "Endemic",
    "icun_assessment": "Endemic",
    "remarks": "Matchwood & plywood"
  },
  {
    "id": 4,
    "scientific_name": "Aphanamixis polystachya",
    "common_name": "Pithraj Tree",
    "icun_assessment": "Endemic",
    "remarks": "Potent ayurvedic medicine"
  },
  {
    "id": 5,
    "scientific_name": "Saraca asoka",
    "common_name": "Sita Ashoka",
    "icun_assessment": "Vulnerable",
    "remarks": "Medicinal"
  },
  {
    "id": 6,
    "scientific_name": "Cinnamomum malabatrum",
    "common_name": "Endemic",
    "icun_assessment": "Endemic and Vulnerable",
    "remarks": "A source of spice and medicine"
  },
  {
    "id": 7,
    "scientific_name": "Diospyros malabarica ",
    "common_name": "Indian persimmon",
    "icun_assessment": "Endemic",
    "remarks": "Treatment of blood diseases, Gonorrhea and leprosy"
  },
  {
    "id": 8,
    "scientific_name": "Michaelia champaca",
    "common_name": "Champaka",
    "icun_assessment": "Near Threatened",
    "remarks": "Sacred Tree; Source of perfume"
  },
  {
    "id": 9,
    "scientific_name": "Garcinia indica",
    "common_name": "Kokum",
    "icun_assessment": "Endemic and Vulnerable",
    "remarks": "Super food"
  },
  {
    "id": 10,
    "scientific_name": "Terminalia  chebula",
    "common_name": "Haritha",
    "icun_assessment": "Near Threatened",
    "remarks": "Medicinal"
  },
  {
    "id": 11,
    "scientific_name": "Artocarpus hirsutus",
    "common_name": "Aini",
    "icun_assessment": "Endemic and vulnerable",
    "remarks": "Source of valuable timber and medicine"
  },
  {
    "id": 12,
    "scientific_name": "Oroxylum indicum",
    "common_name": "Somyaka",
    "icun_assessment": "Vulnerable",
    "remarks": "Dashamoola Plant"
  },
  {
    "id": 13,
    "scientific_name": "Terminalia  chebula",
    "common_name": "Haritha",
    "icun_assessment": "Near Threatened",
    "remarks": "Medicinal"
  },
  {
    "id": 14,
    "scientific_name": "Toona Ciliata",
    "common_name": "Red Cedar",
    "icun_assessment": "Endemic",
    "remarks": "Used for treating cough, bronchitis, joint pain"
  },
  {
    "id": 15,
    "scientific_name": "Mimusops Elengi",
    "common_name": "Indian Medlar",
    "icun_assessment": "Endemic",
    "remarks": "Several Ayurvedic uses as astringent, cooling, anthelmintic "
  },
  {
    "id": 16,
    "scientific_name": "Madhuca Longifolia",
    "common_name": "Mahua",
    "icun_assessment": "Vulnerable",
    "remarks": "Potent ayurvedic medicine"
  },
  {
    "id": 17,
    "scientific_name": "Terminalia Bellirica",
    "common_name": "Baheda",
    "icun_assessment": "Endemic",
    "remarks": "Treatment of respiratory illnesses"
  },
  {
    "id": 18,
    "scientific_name": "Chukrasia tabularis",
    "common_name": "Chittagong Wood",
    "icun_assessment": "Endemic",
    "remarks": "Powerful astringent"
  },
  {
    "id": 19,
    "scientific_name": "Aegle marmelos",
    "common_name": "Bael tree",
    "icun_assessment": "Near Threatened",
    "remarks": "Pulp used as summer drink"
  },
  {
    "id": 20,
    "scientific_name": "Mimusops elengi",
    "common_name": "Indian medlar",
    "icun_assessment": "Endemic",
    "remarks": "Edible fruit and timber"
  }
];
